import { graphql } from "gatsby"
import React, { FC } from "react"
import CaseStudySubPage from "src/components/CaseStudySubPage"
import { Props } from "./types"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
    markdownRemark: {
      frontmatter: { title, quote, featuredImage },
      html,
    },
  },
}) => (
  <CaseStudySubPage
    html={html}
    title={title}
    quote={quote}
    featuredImage={featuredImage}
    caseStudy="aurea"
    caseStudies={["bench", "dribbble", "webuild"]}
    {...texts}
  />
)

export const pageQuery = graphql`
  query($ymlPath: String!, $mdPath: String!) {
    markdownRemark(fileAbsolutePath: { eq: $mdPath }) {
      html
      frontmatter {
        title
        quote
        featuredImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }

    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        caseStudiesTitle
        seeAll
        logo {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Template
